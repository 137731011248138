/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function() {
    jQuery.expr[':'].containsNC = function(elem, index, match) {
        return (elem.textContent || elem.innerText || jQuery(elem).text() || '').toLowerCase().indexOf((match[3] || '').toLowerCase()) >= 0;
    };
}(jQuery));

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
                $('.loader').velocity('fadeOut', 500);
                //imgliquid

                $(".imgLiquidFill").imgLiquid();
                //nav
                var $openbtn = document.getElementById('openbtn');
                $openbtn.onclick = function() {
                    document.getElementById("myNav").style.height = "100%";
                };

                var $closebtn = document.getElementById('closebtn');
                $closebtn.onclick = function() {
                    document.getElementById("myNav").style.height = "0%";
                };

                $('.nav li.menu-item-has-children a').click(function(e){
                   
                    if(!$(this).hasClass('active') && $(this).parent().hasClass('menu-item-has-children') == true) {
                        $('.nav li.menu-item-has-children').removeClass('active');
                        $(this).addClass('active');
                        e.preventDefault();
                    } else {
                        return true;
                    }
                });

                $('.slider-for').slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    asNavFor: '.slider-nav',
                    arrows: true,
                    prevArrow: '<button class="PrevArrow"></button>',
                    nextArrow: '<button class="NextArrow"></button>'
                });

                $('.slider-nav').slick({
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    asNavFor: '.slider-for',
                    dots: false,
                    arrows: false,
                    vertical: true,
                    focusOnSelect: true,
                    responsive: [{
                        breakpoint: 768,
                        settings: {
                            vertical: false
                        }
                    }]
                });

                function sizes() {
                    //==============================//

                    var g = 0;

                    $(".filter-by").each(function() {
                        div = $(this);
                        div.css('height', 'auto');

                        if (div.outerHeight() > g) {
                            g = div.outerHeight();
                        }
                    });

                    $(".filter-by").each(function() {
                        $(this).css('height', g);
                    });

                    //==============================//

                    var q = 0;

                    $(".list-maker").each(function() {
                        div = $(this);
                        div.css('height', 'auto');

                        if (div.outerHeight() > q) {
                            q = div.outerHeight();
                        }
                    });

                    $(".list-maker").each(function() {
                        $(this).css('height', q);
                    });

                    //==============================//

                    var y = 0;

                    $(".color-red").each(function() {
                        div = $(this);
                        div.css('height', 'auto');

                        if (div.outerHeight() > y) {
                            y = div.outerHeight();
                        }
                    });

                    $(".color-red").each(function() {
                        $(this).css('height', y);
                    });

                    //==============================//

                    var t = 0; // the height of the highest element (after the function runs)

                    $(".in-meest-bekeken").each(function() {
                        div = $(this);
                        div.css('height', 'auto');

                        if (div.outerHeight() > t) {
                            t = div.outerHeight();
                        }
                    });

                    $(".in-meest-bekeken").each(function() {
                        $(this).css('height', t);
                    });

                    //==============================//

                    var n = 0;

                    $(".hero-event .in-block").each(function() {
                        div = $(this);
                        div.css('height', 'auto');

                        if (div.outerHeight() > n) {
                            n = div.outerHeight();
                        }
                    });

                    $(".hero-event .in-block").each(function() {
                        $(this).css('height', n);
                    });
                }

                sizes();

                $(window).resize(function() {
                    sizes();
                });

                var didScroll;
                var lastScrollTop = 0;
                var delta = 5;
                var header = $('.header_wrap');
                var navbarHeight = header.outerHeight();

                function hasScrolled() {
                    var st = $(this).scrollTop();
                    // Make sure they scroll more than delta
                    if (Math.abs(lastScrollTop - st) <= delta) {
                        return;
                    }

                    // If they scrolled down and are past the navbar, add class .nav-up.
                    // This is necessary so you never see what is "behind" the navbar.
                    if (st <= 50) {
                        header.velocity("stop");
                        header.velocity({ translateZ: 0, translateY: '0px' }, { easing: 'ease', duration: 200 });
                        header.removeClass('nav_fade-in');
                    } else if (st > lastScrollTop) {
                        // Scroll Down
                        header.velocity("stop");
                        header.velocity({ translateZ: 0, translateY: '-60px' }, { easing: 'ease', duration: 200 });
                        header.removeClass('nav_fade-in');
                    } else {
                        // Scroll Up
                        if (st + $(window).height() < $(document).height()) {
                            header.velocity("stop");
                            header.velocity({ translateZ: 0, translateY: '0px' }, { easing: 'ease', duration: 200 });
                            header.addClass('nav_fade-in');
                        }

                    }

                    lastScrollTop = st;
                }
                $(window).scroll(function(event) {
                    didScroll = true;
                });

                setInterval(function() {
                    if (didScroll) {
                        hasScrolled();
                        didScroll = false;
                    }
                }, 100);

                $('body, html').scrollTop(0);

                //filtering by name
                if ($('.search_filter').length > 0) {
                    $('.search_filter').unbind().keyup(function() {
                        var string = $(this).val();
                        var cnt = 0;
                        $('.filter-wrapper').find('.filtering-el').each(function(i, el) {
                            if ($(el).find("p:containsNC('" + string + "')").length) {
                                $(this).fadeIn();
                                cnt++;
                            } else {
                                $(this).fadeOut();
                            }
                        });

                        if (cnt === 0) {
                            $(".filter-wrapper .no-result").show();
                        } else {
                            $(".filter-wrapper .no-result").hide();
                        }
                    });
                }


            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // archive
        'archive': {
            init: function() {
                // JavaScript to be fired on the home page
                function sizes() {
                    
                }
                sizes();

                $(window).resize(function() {
                    sizes();
                });
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
